<template>
  <b-container fluid class="bg-white">
    <b-row>
      <img src="@/assets/icon.png" class="w-75 mx-auto my-3" alt="" />
    </b-row>
    <b-row>
      <div class="mx-5 px-3 w-100" style="text-align: justify">
        <h4 v-html="$t('PrivecyTitle1')"></h4>
        <p v-html="$t('PrivecyDesc1')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle2')"></h4>
        <p v-html="$t('PrivecyDesc2')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle3')"></h4>
        <p v-html="$t('PrivecyDesc3')"></p>
        <br />
        <!-- <h4>{{$t("TermTitle3")}}</h4>
              <p>{{$t("TermDesc2")}}</p>
              <br> -->
        <h4 v-html="$t('PrivecyTitle4')"></h4>
        <p v-html="$t('PrivecyDesc4')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle5')"></h4>
        <p v-html="$t('PrivecyDesc5')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle6')"></h4>
        <p v-html="$t('PrivecyDesc6')"></p>
        <br />
        <!-- <h4 v-html='$t("PrivecyTitle7")' ></h4>
              <p v-html='$t("PrivecyDesc7")'></p>
              <br> -->
        <h4 v-html="$t('PrivecyTitle8')"></h4>
        <p v-html="$t('PrivecyDesc8')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle9')"></h4>
        <p v-html="$t('PrivecyDesc9')"></p>
        <br />
        <h4 v-html="$t('PrivecyTitle10')"></h4>
        <p v-html="$t('PrivecyDesc10')"></p>
        <br />
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  metaInfo: {
    title: "Privacy",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "تقوم سياسة الخصوصية بتوضيح كيفية استخدام المعلومات المدخلة لموقع “ YAMHAD ” وتحمي هذه السياسة أي معلومات يتم تقديمها بواسطة المشتركي بالموقع عند استخدامهم لهذا المَتجر/الموقع .",
      },
    ],
  },
  mounted() {
    this.$gtag.event("Privacy Page", { method: "Google" });
  },
};
</script>

<style>
</style>